import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Title from '../components/page-title'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Copy from '../components/page-copy'

import Image from '../components/page-image-new'
import { theme, responsiveFont, fontRegular } from '../util/style'

const Image1 = 'for-sale/haus-peary.jpg'
const FloorplanImage1 = 'for-sale/'
const FloorplanImage2 = 'for-sale/'

const DesignImage1 = 'for-sale/hp-1.jpg'
const DesignImage2 = 'for-sale/hp-2.jpg'
const DesignImage3 = 'for-sale/hp-3.jpg'
const DesignImage4 = 'for-sale/hp-4.jpg'
const DesignImage5 = 'for-sale/hp-5.jpg'
const DesignImage6 = 'for-sale/hp-6.jpg'
const DesignImage7 = 'for-sale/hp-7.jpg'
const DesignImage8 = 'for-sale/hp-8.jpg'
const DesignImage9 = 'for-sale/hp-9.jpg'
const DesignImage10 = 'for-sale/hp-10.jpg'

const SplitImage = ({ image, label }) => {
  return (
    <SplitImageWrapper mb={[3]}>
      {label ? (
        <SplitImageTextWrapper pr={3} mt={[3, 0]}>
          <Copy>{label}</Copy>
        </SplitImageTextWrapper>
      ) : (
        <Box />
      )}
      <Image src={image} ratio={9 / 16} />
    </SplitImageWrapper>
  )
}

const HausPerry = () => {
  return (
    <>
      <SEO title="Haus Peary" />
      <Box style={{ height: '54px', width: '100%' }} />
      <HeaderWrapper my={[3, 4]}>
        <Title noPaddingTop noMargin>
          For Sale
        </Title>
        <Link
          target="__blank"
          href="https://www.realestate.com.au/property-house-qld-northgate-139676663"
        >
          <PillButton ml={2} as={'button'}>
            Enquire Now
          </PillButton>
        </Link>
      </HeaderWrapper>
      <Wrapper>
        <TitleRow pb={[2, 3]}>
          <LogoWrapper>
            <Link href="/">
              <svg
                width="86"
                height="53"
                viewBox="0 0 86 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M86 0V53H0V22.9461L86 0Z" fill="#303030" />
              </svg>
            </Link>
          </LogoWrapper>
          <TitleWrapper>
            <Title noMargin>Haus Peary - SOLD</Title>
          </TitleWrapper>
        </TitleRow>
        <CopyRow py={[2, 3]}>
          <Box>
            <Box mb={2}>
              <Info>Design</Info>
              <Info style={{ color: '#888888' }}>
                Happy Haus & Jessica Hardwick Architecture
              </Info>
            </Box>
            <Box mb={2}>
              <Info>Build</Info>
              <Info style={{ color: '#888888' }}>Happy Haus</Info>
            </Box>
            <Box>
              <Info>For Sale</Info>
              <Info style={{ color: '#888888' }}>Place Ascot</Info>
            </Box>
          </Box>
          <Title noMargin color="#888888">
            A desirable location, this courtyard home boasts a striking
            contemporary Queenslander facade, accented by timber details and
            classic lines. The exquisite interior features and palette are
            framed by gardens on a 1200m2 site.
          </Title>
        </CopyRow>
        <ImageRow mb={[3, 4]}>
          <Image src={Image1} />
        </ImageRow>
        {/* <PlanRow pt={[1, 2]} mb={[3, 4]}>
          <Title>Plan</Title>
          <SplitImage label="Ground" image={FloorplanImage1} />
          <SplitImage label="Upper" image={FloorplanImage2} />
        </PlanRow> */}
        <DesignRow pt={[1, 2]} mb={[3, 4]}>
          <Title>Design</Title>
          <SplitImage
            label="This brand-new property is set on top of the hill in Northgate's best street and is due to be completed in 12 months."
            image={DesignImage1}
          />

          <SplitImage label="" image={DesignImage2} />
          <SplitImage
            label="With it’s spacious open-plan kitchen, the living and dining area on the ground level forms the heart of Peary Street Haus."
            image={DesignImage3}
          />
          <SplitImage image={DesignImage4} />
          <SplitImage
            label="A sunken living space, the covered outdoor room with the brick fireplace and a striking contemporary Queenslander facade."
            image={DesignImage5}
          />
          <SplitImage label="" image={DesignImage6} />
          <SplitImage label="" image={DesignImage7} />
          <SplitImage label="" image={DesignImage8} />
          <SplitImage
            label="Continuing the entertainment options, a covered alfresco adjoins an open-air kitchen and a brick-clad fireplace."
            image={DesignImage9}
          />
          <SplitImage
            label="Set on top of the hill, the 1200m2 site in Northgate six-bedroom luxury home is the perfect example of contemporary refinement."
            image={DesignImage10}
          />
        </DesignRow>
      </Wrapper>
    </>
  )
}
export default HausPerry

const Wrapper = styled(Box)``
const LogoWrapper = styled(Box)``
const SplitRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const CopyRow = styled(SplitRow)``
const TitleRow = styled(SplitRow)`
  border-bottom: 1px solid #c4c4c4;
`
const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`
const ImageRow = styled(Box)``
const PlanRow = styled(Box)`
  border-top: 1px solid #c4c4c4;
`

const SplitImageWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 30% 70%;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`
const SplitImageTextWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`

const DesignRow = styled(Box)`
  border-top: 1px solid #c4c4c4;
`

const ContactRow = styled(SplitImageWrapper)`
  a {
    color: #8888;
    text-decoration-color: #8888;
  }
`

const Info = styled(Box)``

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const PillButton = styled(Box)`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  padding: 12px 20px;
  cursor: pointer;
  color: #31333a !important;
`

const Notifbar = styled(Box)`
  background-color: #303030;
  color: white;
  text-align: center;
  ${fontRegular}

  a {
    color: white;
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const ContactText = styled(Box)`
  color: #888888;
`
